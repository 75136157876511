import './App.css';
import logo from "./images/logo.png"
import meme from "./images/meme.png"
import twitter from "./images/twitter.png"
import telegram from "./images/telegram.png"
import { useEffect, useState } from 'react';

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Hehenomics/>
      <Footer/>
    </div>
  );
}

function Header(){
  return(
    <div id='HeaderContainer'>
      <img src={logo} alt="logo" style={{width:"50px"}}/>
      <div id='MenuItems'>
        <a href='#Hehenomics'>Hehenomics</a>
        <a href='#Hehemap'>Hehemap</a>
        <a href='#Socials'>Socials</a>
        <a href='https://linktr.ee/hehecoin'>Links</a>
      </div>
    </div>
  )
}
function Hero(){
  return(
  <div id='HeroContainer'>
    <img alt="meme" src={meme}></img>
    <div id='HeroText'>
      <h1>The Ultimate Meme Token Is Here!</h1>
      <span>Introducing "$HEHE", the latest meme token to grace the cryptocurrency scene. Born from the contagious laughter of online communities, $HEHE aims to bring joy, humor, and a touch of mischief to the world of digital currencies. With its vibrant and playful logo featuring an adorable laughing face, $HEHE captures the spirit of laughter and creates a lighthearted atmosphere within the crypto space. As a meme token, $HEHE encourages users to embrace the lighter side of investing and engage with its community through humorous and entertaining content. With a limited and cool token supply, $HEHE invites you to join the fun and let the laughter echo through the blockchain, spreading happiness one transaction at a time. So hop on board the $HEHE train and experience the joyous journey of this giggling revolution!</span>
      <div id='ButtonContainer'>
        <button onClick={() => window.open("https://pancakeswap.finance/swap?outputCurrency=0x1c0408bff61365333db9f4fd180e8c83a2e746ac")} style={{background:"green", color:"lime"}}>Buy $HEHE</button>
        <button onClick={() => window.open("https://www.dextools.io/app/en/bnb/pair-explorer/0xd76feb700e1580dce2a13bf661a556b27db57d9d")} style={{background:"cyan"}}>Check Dextools</button>
        <button onClick={() => window.open("https://t.me/hehenothehecoin")} style={{background:"navy", color:"white"}}>Join Telegram</button>
      </div>
    </div>
  </div>
  )
}
function Hehenomics(){
  const [price,setPrice] = useState("-")
  useEffect(()=>{
    async function getPrice(){
      let req = await fetch("https://api.dexscreener.com/latest/dex/tokens/0x1c0408bff61365333db9f4fd180e8c83a2e746ac")
      let res = await req.json()
      let price = res.pairs[0].priceUsd
      setPrice(Number(price).toFixed(4))
    }
    getPrice()
  },[])
  return(
    <div id='Hehenomics'>
      <h1>Hehenomics</h1>
      <div id="HehenomicsContainer">
        <div id='HehenomicsCell'>
          <img alt="logo" src={logo}/>
          <h2>5% Buy/Sell Tax</h2>
          <span>We have a tiny bit of buy and sell tax on $HEHE token. Just to make sure to cover the development and marketing expenses.</span>
        </div>
        <div id='HehenomicsCell'>
          <img alt="logo" src={logo}/>
          <h2>0 Team Tokens</h2>
          <span>There are no team, presale, privatesale, bullshit sale tokens. All of them are for the community!</span>
        </div>
        <div id='HehenomicsCell'>
          <img alt="logo" src={logo}/>
          <h2>100% For the Community</h2>
          <span>No tokens were burnt on launch to inflate numbers. Everything is real and fun! All into the LP! HEHE!</span>
        </div>
      </div>
      <div style={{display:"grid", gap:"10px"}}>
        <h1>Total Supply</h1>
        <h2>420,069 $HEHE</h2>
        <h2>$HEHE Price: {price}$</h2>
        <span>LP tokens are burnt, HEHE! <a style={{color:"green"}} href="https://bscscan.com/tx/0x7ceecfd48b996bb7e2fd829c67cf8d00ba966aa415adb0c79ccdabab39d6da73">Click Here to see it on BSCscan</a></span>
        <span>Ownership is renounced, HEHE! <a style={{color:"green"}} href="https://bscscan.com/tx/0x1841986971d7d79f4aa3593e9018cbff136155d1647940bcb620a1b1d9c10d90">Click Here to see it on BSCscan</a></span>
      </div>
      <div id='Hehemap' style={{justifySelf:"center", marginTop:"20px"}}>
        <h1>Hehemap</h1>
        <div id="HehenomicsContainer">
        <div id='HehenomicsCell'>
          <h2 style={{color:"lime"}}>1st Laugh</h2>
          <span>We launch the token and ape. That's our first laugh.</span>
        </div>
        <div id='HehenomicsCell'>
          <h2>2nd Laugh</h2>
          <span>We hop on popular listing platforms and get our voice on social media.</span>
        </div>
        <div id='HehenomicsCell'>
          <h2>3rd Laugh</h2>
          <span>$HEHE gets listed on big exchanges and our community is at a bonanza!</span>
        </div>
        </div>
      </div>
      <div id='Socials'>
        <h1>Socials</h1>
        <div id='SocialsContainer'>
          <a href='https://twitter.com/hehenothehecoin'><img alt="twitter" src={twitter} style={{width:"100px"}}></img></a>
          <a href='https://t.me/hehenothehecoin'><img alt="telegram" src={telegram} style={{width:"100px"}}></img></a>
        </div>
      </div>
      <div id='Disclaimer'>
        <h1>Disclaimer</h1>
        <span>$HEHE - not hehe token is a joke with no intrinsic value or expectation of financial return. There is no formal team or a roadmap. The $HEHE token is completely useless and for entertainment purposes only.</span>
      </div>
    </div>
  )
}
function Footer(){
  return(
    <div id='Footer'>
      <span>$HEHE - not hehe 2023</span>
      <div id='MenuItems' >
        <a style={{color:"white"}} href='#Hehenomics'>Hehenomics</a>
        <a style={{color:"white"}} href='#Hehemap'>Hehemap</a>
        <a style={{color:"white"}} href='#Socials'>Socials</a>
        <a style={{color:"white"}} href='https://linktr.ee/hehecoin'>Links</a>
      </div>
    </div>
  )
}

export default App;
